// Heavily inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
// You can read more there about this pattern
import React, { useReducer, useContext, useEffect } from 'react'
import * as R from 'ramda'
import { asyncReducer, useAsyncDispatch } from '@upvestcz/common/hooks/use-async-dispatch'
import { ACCOUNT_STATUS } from '@upvestcz/common/account-utils'
import { CURRENCIES } from '@upvestcz/common/currency'
import { Decimal } from 'decimal.js-light'

const AccountStateContext = React.createContext()
const AccountDispatchContext = React.createContext()

const reducer = asyncReducer((state, action) => {
    switch (action.type) {
        case 'set':
            return action.payload
        case 'clear':
            return null
        case 'conclude':
            return { ...state, status: ACCOUNT_STATUS.PENDING }
        case 'withdrawal':
            if (action.payload.currency === CURRENCIES.EUR) {
                return {
                    ...state,
                    balance_eur: new Decimal(state.balance_eur)
                        .minus(action.payload.amount)
                        .toNumber(),
                    withdrawal_requests_eur_sum: new Decimal(state.withdrawal_requests_eur_sum)
                        .plus(action.payload.amount)
                        .toNumber(),
                }
            }
            return {
                ...state,
                balance: new Decimal(state.balance).minus(action.payload.amount).toNumber(),
                withdrawal_requests_sum: new Decimal(state.withdrawal_requests_sum)
                    .plus(action.payload.amount)
                    .toNumber(),
            }
        case 'investment':
            if (action.payload.currency === CURRENCIES.EUR) {
                return {
                    ...state,
                    balance_eur: new Decimal(state.balance_eur)
                        .minus(action.payload.amount)
                        .toNumber(),
                    investments_eur_sum: new Decimal(state.investments_eur_sum)
                        .plus(action.payload.amount)
                        .toNumber(),
                }
            }
            return {
                ...state,
                balance: new Decimal(state.balance).minus(action.payload.amount).toNumber(),
                investments_sum: new Decimal(state.investments_sum)
                    .plus(action.payload.amount)
                    .plus(state.pending_referral_bonuses_sum)
                    .toNumber(),
                pending_referral_bonuses_sum: 0,
            }
        case 'update':
            return R.merge(state, action.payload)
        default:
            throw new Error(`An unknown action of type ${action.type} passed`)
    }
})

function AccountProvider({ initialValue, children }) {
    const [state, dispatch] = useReducer(reducer, initialValue || null)

    const promisifiedDispatch = useAsyncDispatch(dispatch)

    useEffect(() => {
        // bind the state to the window for reuse in getInitialProps
        // inspired here: https://github.com/zeit/next.js/blob/canary/examples/with-redux/lib/with-redux-store.js
        // eslint-disable-next-line no-underscore-dangle
        window.__ACCOUNT_STATE__ = state
    }, [state])

    return (
        <AccountStateContext.Provider value={state}>
            <AccountDispatchContext.Provider value={promisifiedDispatch}>
                {children}
            </AccountDispatchContext.Provider>
        </AccountStateContext.Provider>
    )
}

AccountProvider.defaultProps = {
    initialValue: null,
}

function useAccountState() {
    const context = useContext(AccountStateContext)

    if (context === undefined) {
        throw new Error('useAccountState must be used within a AccountProvider')
    }

    return context
}

function useAccountDispatch() {
    const context = useContext(AccountDispatchContext)

    if (context === undefined) {
        throw new Error('useAccountDispatch must be used within a AccountProvider')
    }

    return context
}

function useAccount() {
    return [useAccountState(), useAccountDispatch()]
}

export { AccountProvider, useAccount }
