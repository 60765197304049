import Router from 'next/router'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { usePrevious } from '@upvestcz/common/hooks'

export const simpleFetch = async (URL: string) => {
    const res = await fetch(URL)

    // Ok request
    if (!res.ok) {
        throw res
    }
    if (res.status === 204) {
        return null
    }
    if (res.headers?.get('Content-Type')?.includes('application/json')) {
        const result = await res.json()
        return result
    }
    return res
}

export const useBuildId = () => {
    const [buildId, setBuildId] = useState('') // initiate buildId with empty string

    const { data } = useSWR('/api/build-id', simpleFetch, { refreshInterval: 60000 }) as {
        data: { buildId: string } // fetch buildID and type the output, refresh every 30 seconds
    }

    useEffect(() => {
        if (data && !!data.buildId) {
            // set buildId to the fetched buildId
            setBuildId(data.buildId)
        }
    }, [data])

    return buildId
}

export const useForceVersionUpdateHook = () => {
    const buildId = useBuildId() // useSWR under the hood
    const prevBuildId = usePrevious(buildId)

    useEffect(() => {
        if (prevBuildId && buildId && prevBuildId !== buildId) {
            Router.reload()
        }
    }, [buildId, prevBuildId])
}
