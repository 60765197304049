import React from 'react'
import { useSpring, animated } from 'react-spring'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInfoCircle,
    faExclamationCircle,
    faCheckCircle,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import Button from './Button'
import css from '../styles/Toast.module.sass'

const getIconSvg = type => {
    switch (type) {
        case 'info':
            return faInfoCircle
        case 'error':
            return faExclamationCircle
        case 'success':
            return faCheckCircle
        default:
            throw new Error(`An unknown type ${type} passed.`)
    }
}

function Toast({ children, type = 'success', onRemoveClick }) {
    const INACTIVE_BOX_SHADOW =
        '0 4px 6px -1px rgba(255, 255, 255, 0.12), 0 2px 4px -1px rgba(255, 255, 255, 0.08)'
    const ACTIVE_BOX_SHADOW =
        '0 10px 15px -3px rgba(255, 255, 255, 0.15), 0 4px 6px -2px rgba(255, 255, 255, 0.1)'

    const [styleProps, set] = useSpring(() => ({
        boxShadow: INACTIVE_BOX_SHADOW,
    }))

    const setActiveBoxShadow = () => {
        set({ boxShadow: ACTIVE_BOX_SHADOW })
    }
    const setInactiveBoxShadow = () => {
        set({ boxShadow: INACTIVE_BOX_SHADOW })
    }

    return (
        <animated.div
            style={styleProps}
            onClick={e => {
                e.nativeEvent.stopImmediatePropagation()
            }}
            className={cx(css.toast, css[type])}
            onMouseOver={setActiveBoxShadow}
            onFocus={setActiveBoxShadow}
            onTouchStart={setActiveBoxShadow}
            onBlur={setInactiveBoxShadow}
            onMouseLeave={setInactiveBoxShadow}
            onTouchEnd={setInactiveBoxShadow}
        >
            <div className={css.icon}>
                <FontAwesomeIcon icon={getIconSvg(type)} size="2x" />
            </div>
            <div>{children}</div>
            <Button
                level="icon"
                onClick={onRemoveClick}
                className={css.iconRemove}
                onTouchStart={e => {
                    e.stopPropagation()
                }}
                onTouchMove={e => {
                    e.stopPropagation()
                }}
                onTouchEnd={e => {
                    e.stopPropagation()
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        </animated.div>
    )
}

export default Toast
