/* eslint-disable no-underscore-dangle */
import React, { useCallback } from 'react'
import * as R from 'ramda'
import { TokenExpiredError } from '@upvestcz/common/errors'
import { replace } from '@upvestcz/common/routing'
import {
    loggedIn,
    getProfile,
    getToken,
    getAccessToken,
    checkJwtTokenExpiration,
    destroyLoginCookies,
} from '@upvestcz/common/auth'
import { setCookie } from '@upvestcz/common/cookies'

import { fetchAccount, createAccount } from '../lib/api'
import { useAccount, AccountProvider } from './account'
import { AuthProvider, useAuth } from './auth'
import logger from '../lib/logger'

const IS_SERVER = typeof window === 'undefined'

export const useClearStore = () => {
    const [, dispatchAccount] = useAccount()
    const [, dispatchAuth] = useAuth()

    return useCallback(
        () => Promise.all([dispatchAccount({ type: 'clear' }), dispatchAuth({ type: 'clear' })]),
        [dispatchAccount, dispatchAuth],
    )
}

const initialDataFetch = async ({ ctx }) => {
    const { auth_id } = ctx.auth.profile
    // gets the data from the API (when run on the server)
    // or from the React Context which is bound to the `window` object
    // as the `getInitialProps` method hasn't got the access
    // to React Context
    const getFromApiOrContext = ({ request, globalContextKey }) =>
        IS_SERVER ? request(ctx).then(R.prop('data')) : Promise.resolve(window[globalContextKey])

    const nonAuthPromises = []

    const authPromises = [
        () => {
            return getFromApiOrContext({
                request: ctx => fetchAccount(auth_id, ctx),
                globalContextKey: '__ACCOUNT_STATE__',
            })
                .then(account => ({ account }))
                .catch(err => {
                    // If user hasn't got an account record in the DB
                    // (but *does* have got an Auth0 profile), create it
                    // as the whole app assumes that every user has got an account
                    if (err.status === 404) {
                        return createAccount(auth_id, { email: ctx.auth.profile.email }, ctx).then(
                            ({ data }) => ({ account: data }),
                        )
                    }

                    throw err
                })
        },
    ]

    const finalPromises = ctx.auth.loggedIn ? authPromises : nonAuthPromises

    return R.mergeAll(await Promise.all(R.map(fn => fn(), finalPromises)))
}

export function withStore(Component) {
    const WithStore = ({ account, auth, ...props }) => {
        return (
            <AccountProvider initialValue={account}>
                <AuthProvider initialValue={auth}>
                    <Component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                    />
                </AuthProvider>
            </AccountProvider>
        )
    }

    WithStore.getInitialProps = async ctx => {
        const getAuth = ctx => ({
            loggedIn: loggedIn(ctx.ctx),
            profile: getProfile(ctx.ctx),
            token: getToken(ctx.ctx),
            accessToken: getAccessToken(ctx.ctx),
        })

        const auth = getAuth(ctx)

        if (auth.token || auth.accessToken) {
            /*
            If we ever use refresh_token to remember the user, this needs to be remade
            into a post request and checked server-side via jwt.verify and the refresh.
             */
            try {
                // if any one of these expires, logout.
                checkJwtTokenExpiration(auth.token)
                checkJwtTokenExpiration(auth.accessToken)
            } catch (err) {
                if (err instanceof TokenExpiredError) {
                    // Don't log anything. Login expiration is an expected state.
                    // The cookies don't get applied until the next request. (destroyCookie sets a Set-Cookie header)
                    destroyLoginCookies(ctx.ctx)
                    // expiration detection cannot be passed as a prop because of possible redirects in other `getInitialProps` HOC's
                    setCookie(ctx.ctx, 'tokenExpired', 'true')

                    // replace auth object with signed out values
                    Object.assign(auth, getAuth({}))
                    return replace({
                        ctx: ctx.ctx,
                        location: null,
                    })
                }
                logger.error(err)
            }
        }

        if (typeof Component.getInitialProps === 'function') {
            const pageProps = await Component.getInitialProps({
                ...ctx,
                ctx: {
                    ...ctx.ctx,
                    auth,
                    initialDataFetch,
                },
            })

            return { ...pageProps, auth }
        }

        return { auth }
    }

    return WithStore
}
