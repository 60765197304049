// Heavily inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
// You can read more there about this pattern
import React, { useReducer, useContext, useEffect } from 'react'
import { asyncReducer, useAsyncDispatch } from '@upvestcz/common/hooks/use-async-dispatch'

const AuthStateContext = React.createContext()
const AuthDispatchContext = React.createContext()

const reducer = asyncReducer((state, action) => {
    switch (action.type) {
        case 'set':
            return action.payload
        case 'clear':
            return {
                loggedIn: false,
                profile: {},
                token: null,
            }
        default:
            throw new Error(`An unknown action of type ${action.type} passed`)
    }
})

function AuthProvider({ initialValue, children }) {
    const [state, dispatch] = useReducer(reducer, initialValue || null)

    const promisifiedDispatch = useAsyncDispatch(dispatch)

    useEffect(() => {
        // bind the state to the window for reuse in getInitialProps
        // inspired here: https://github.com/zeit/next.js/blob/canary/examples/with-redux/lib/with-redux-store.js
        // eslint-disable-next-line no-underscore-dangle
        window.__AUTH_STATE__ = state
    }, [state])

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={promisifiedDispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

AuthProvider.defaultProps = {
    initialValue: null,
}

function useAuthState() {
    const context = useContext(AuthStateContext)

    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider')
    }

    return context
}

function useAuthDispatch() {
    const context = useContext(AuthDispatchContext)

    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider')
    }

    return context
}

function useAuth() {
    return [useAuthState(), useAuthDispatch()]
}

export { AuthProvider, useAuth }
