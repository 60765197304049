import { ReactNode } from 'react'
import { Box, Link, Img } from '@upvestcz/shared-components'
import logo from '@upvestcz/shared-components/images/upvest_logo_black.svg'

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
    return (
        <Box>
            <Link display="inline-block" to="/" position="absolute" top={6} left={4}>
                <Img src={logo.src} alt="logo" maxW="150px" />
            </Link>
            <Box>{children}</Box>
        </Box>
    )
}

export const getDefaultLayout = (page: ReactNode, props = {}) => (
    <DefaultLayout {...props}>{page}</DefaultLayout>
)

export default DefaultLayout
