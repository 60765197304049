import i18n from 'i18next'
import * as R from 'ramda'
import { Locale, LOCALES } from '@upvestcz/common/i18n/locales'
import * as formatters from '@upvestcz/common/i18n/formatters'
import { initializeI18n, LocaleTranslationsMap } from '@upvestcz/common/i18n'
import { ensureArray } from '@upvestcz/common/ts-utils'

export default initializeI18n(i18n, {
    resources: {
        // none by default. Translation files are loaded asynchronously in _app's getInitialProps.
    },
    formatters: R.pick(
        ['formatAmount', 'formatCurrency', 'formatDateTime', 'formatDayMonthYear'],
        formatters,
    ),
})

export const tryToLoadTranslations = async ({
    relativeFilePath,
    locale = [LOCALES.CS, LOCALES.EN],
}: {
    relativeFilePath: string
    locale?: Locale | Locale[]
}) => {
    try {
        const folderPath = './public/locales'

        const translations = await Promise.all(
            ensureArray(locale).map(async locale => {
                const translations = (
                    await import(`${folderPath}/${locale}/${relativeFilePath}.json`)
                ).default as Record<string, string>

                return [locale, translations] as const // [Locale, Record<string, string>]
            }),
        ).then(res => Object.fromEntries(res) as LocaleTranslationsMap)

        return { translations }
    } catch (err: TODO) {
        if (err.code === 'MODULE_NOT_FOUND') {
            return {}
        }
        throw err
    }
}
