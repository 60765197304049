import { NextPageContext } from 'next'
import 'isomorphic-fetch'
import * as R from 'ramda'
import queryString from 'query-string'
import localforage from 'localforage'
import { replace } from '@upvestcz/common/routing'
import { getApiUrl } from '@upvestcz/common/api'
import { ERROR_CODES } from '@upvestcz/common/errors'
import { getAccessTokenFromCookies, Profile } from '@upvestcz/common/auth'
import { getCookieHeaderFromCtx } from '@upvestcz/common/cookies'
import type Bull from 'bull'
import { Currency } from '@upvestcz/common/currency'

const API_URL = getApiUrl()

const { stringify } = JSON

type ObjectWithData<T> = { data?: null | T }

/*
 * API fetch wrapper with JSON parsing
 */
export async function api<T = TODO>(endpoint: string, options: RequestInit, ctx?: NextPageContext) {
    // Use relative URLs in the browser and absolute URLs on the server to correctly proxy client-side requests through this app
    const baseUrl = API_URL

    const cookie = ctx ? getCookieHeaderFromCtx(ctx, false) : undefined
    const accessToken = getAccessTokenFromCookies(ctx)

    const res: Response & ObjectWithData<T> = await fetch(`${baseUrl}${endpoint}`, {
        credentials: 'include',
        ...options,
        headers: {
            ...(options.headers ? options.headers : {}),
            ...(cookie ? { Cookie: cookie } : undefined),
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined),
        },
    })

    // Handle unauthorized request
    if (res.status === 401) {
        const cookies: Record<string, string> = {
            profile: '',
            id_token: '',
            access_token: '',
        }

        try {
            const { code } = await res.json()
            if (code && code === ERROR_CODES.TOKEN_EXPIRED) {
                cookies.tokenExpired = 'true'
            }
            // eslint-disable-next-line no-empty
        } catch {}

        replace({
            ctx,
            location: '/',
            cookies,
        })
    }
    // Ok request
    if (!res.ok) {
        throw res
    }
    if (res.status === 204) {
        res.data = null
    } else if ((options.headers as Record<string, string>)?.Accept === 'application/json') {
        res.data = await res.json()
    }
    return res
}

export function fetcher<T>(url: string, ctx?: NextPageContext): Promise<T | null> {
    return api<T>(
        url,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    ).then(response => R.prop('data', response) || null)
}

export async function cachedFetcher<T extends unknown[]>(baseUrl: string) {
    const storageKey = `__REQUESTS__/${baseUrl}`

    type CacheItem = { items?: T; lastUpdatedAt?: string }

    const cache = await localforage.getItem<CacheItem>(storageKey)

    const { items: storedItems = [], lastUpdatedAt } = cache || {}

    const url = queryString.stringifyUrl({
        url: baseUrl,
        query: { lastUpdatedAt },
    })

    const newLastUpdatedAt = new Date().toISOString()

    return fetcher<T>(url).then(apiItems => {
        if (apiItems) {
            const apiItemsMap = R.indexBy(R.prop('id') as TODO, apiItems)
            const updatedItemsIdsMap: Record<string, boolean> = {}

            // updated existing items which have changed
            const updatedItems = storedItems.map((item: TODO) => {
                if (item.id && apiItemsMap[item.id]) {
                    updatedItemsIdsMap[item.id] = true
                    return apiItemsMap[item.id]
                }

                return item
            })

            // get the new items (i. e. items which weren't stored yet)
            const newItems = apiItems.filter((item: TODO) => !updatedItemsIdsMap[item.id])

            const allItems = newItems.concat(updatedItems)

            localforage.setItem(storageKey, {
                items: allItems,
                lastUpdatedAt: newLastUpdatedAt,
            })

            return allItems
        }

        return apiItems
    })
}

/*
====== SHARED ======
 */

/**
 * GET account
 */
export function fetchAccount(accountId: TODO, ctx?: NextPageContext) {
    return api(
        `/api/accounts/${accountId}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * POST log in
 */
export function login(payload: TODO, ctx?: NextPageContext) {
    return api<{
        profile: Profile
        id_token: string
        access_token: string
    }>(
        `/api/auth/login`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * Update user
 */
export function createAccount(accountId: TODO, payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/accounts/${accountId}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * GET opportunities
 */
export function fetchOpportunities(ctx?: NextPageContext) {
    return api(
        `/api/opportunities/`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * GET opportunity
 */
export function fetchOpportunity(id: TODO, ctx?: NextPageContext) {
    return api(
        `/api/opportunities/${id}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * PUT edit corporate person
 */
export function editCorporatePerson(id: TODO, payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/corporate-persons/${id}`,
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * DELETE corporate person
 */
export function deleteCorporatePerson(id: number, ctx?: NextPageContext) {
    return api(
        `/api/corporate-persons/${id}`,
        {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/*
============ ADMIN ONLY ===========
 */

/**
 * Approve user
 */
export function checkDocument(type: TODO, number: TODO) {
    return api(`/api/invalid-id-check/${type}/${number}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

/**
 * Approve user
 */
export function approveAccount(accountId: TODO) {
    return api(`/api/accounts/${accountId}/approve`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

/**
 * Reject user
 */
export function rejectAccount(payload: TODO) {
    return api(`/api/accounts/${payload.accountId}/reject`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/**
 * Remove user
 */
export function removeAccount(payload: TODO) {
    return api(`/api/accounts/${payload.accountId}/remove`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/**
 * Preview user rejection e-mail
 */
export function previewAccountRejection(payload: TODO) {
    return api(`/api/accounts/${payload.accountId}/preview-rejection`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/**
 * GET account
 */
export function fetchAccountWithDetails(accountId: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/accounts/${accountId}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * Update account details
 */
export function updateAccountDetails(accountId: TODO, payload: TODO) {
    return api(`/api/admin/accounts/${accountId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/**
 * Get risk profile with score
 */
export function updateAccountRiskProfileScore(accountId: TODO) {
    return api(`/api/admin/accounts/${accountId}/risk-profile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

export function fetchOpportunityReferrals(id: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/opportunities/${id}/referrals`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

export function previewOpportunityReport(body: TODO) {
    return api(`/api/opportunities/reports/preview`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify({ ...body }),
    })
}

export function createOpportunityReport(
    id: number,
    body: { title: string; desc: string; url: string },
) {
    return api(`/api/opportunities/${id}/reports`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify({ ...body, opportunity_id: id }),
    })
}

export function removeOpportunityReport(id: number, reportId: number) {
    return api(`/api/opportunities/${id}/reports`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify({ report_id: reportId }),
    })
}

export function sendOpportunityReports(body: { reportIds: number[] }) {
    return api(`/api/opportunities/reports/send-report-email`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify({ ...body }),
    })
}

/**
 * GET transaction
 */
export function fetchTransaction(id: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/transactions/${id}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * GET withdrawal requests
 */
export function fetchWithdrawalRequests(ctx?: NextPageContext) {
    return api(
        `/api/admin/withdrawal-requests`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * Edit withdrawal
 */
export function editWithdrawalRequests(payload: TODO) {
    return api(`/api/admin/withdrawal-requests`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

export function previewYieldPayoff(
    payload: {
        investment_id: number
        account_id: number
        payout: number
        principal: number
        interest: number
    }[],
    ctx?: NextPageContext,
) {
    return api<
        {
            email: string // HTML
            payout: {
                email: string // user email
                amount: number
                currency: Currency
            }
        }[]
    >(
        `/api/admin/preview-yield-payoff`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        },
        ctx,
    )
}

export function commitYieldPayoff(
    payload: {
        jsonData: {
            investment_id: number
            account_id: number
            payout: number
            principal: number
            interest: number
            currency: Currency
        }[]
        dateValid: string
    },
    ctx?: NextPageContext,
) {
    return api<{ jobIds: Bull.JobId[] }>(
        `/api/admin/commit-yield-payoff`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        },
        ctx,
    )
}

/**
 * POST preview participation confirmed
 */
export function previewParticipationConfirmed(
    payload: {
        opportunityId: number
        fromDate?: Date
        toDate?: Date
        confirmationDate: Date
    },
    ctx?: NextPageContext,
) {
    return api<
        {
            to: {
                id: number
                email: string
            }
            content: string // html
            emailData: {
                title: string
                subtitle: string
            }
        }[]
    >(
        '/api/admin/opportunities/preview-confirm-participations',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * POST send participation confirmed
 */
export function sendParticipationConfirmed(payload: TODO, ctx?: NextPageContext) {
    return api<{ jobIds: Bull.JobId[] }>(
        '/api/admin/opportunities/confirm-participations',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        },
        ctx,
    )
}

/**
 * POST preview order execution
 */
export function previewLoanDraw(payload: TODO, ctx?: NextPageContext) {
    return api<
        {
            content: string
            to: {
                id: number
                email: string
            }
            emailData: {
                id: number
                created_at: string
                title: string
                amount: number
                currency: Currency
                execution_date: string
            }
        }[]
    >(
        '/api/admin/opportunities/preview-loan-draw',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        },
        ctx,
    )
}

/**
 * POST send participation confirmed
 */
export function loanDraw(payload: TODO, ctx?: NextPageContext) {
    return api<{ jobIds: Bull.JobId[] }>(
        '/api/admin/opportunities/loan-draw',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function previewLoanPayoff(
    data: {
        investment_id: number
        account_id: number
        payout: number
        principal: number
        interest: number
    }[],
    ctx?: NextPageContext,
) {
    return api<
        {
            emails: {
                payoffConfirmed: string // HTML
                participationDiscarded: string // HTML
            }
            data: {
                email: string // user email
                invested_amount: number
                payout: number
                currency: Currency
            }
        }[]
    >(
        `/api/admin/preview-loan-payoff`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(data),
        },
        ctx,
    )
}

export function commitLoanPayoff(
    payload: {
        jsonData: {
            investment_id: number
            account_id: number
            payout: number
            principal: number
            interest: number
        }[]
        dateValid: string
    },
    ctx?: NextPageContext,
) {
    return api<{ jobIds: Bull.JobId[] }>(
        `/api/admin/commit-loan-payoff`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * GET fetch all referrals
 */
export function fetchAllReferrals(ctx?: NextPageContext) {
    return api(
        `/api/admin/referrals`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * POST create a new investment opportunity
 */
export function createOpportunity(payload: TODO, ctx?: NextPageContext) {
    return api(
        '/api/admin/opportunities/new',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * POST edit an investment opportunity
 */
export function editOpportunity<
    OpportunityShape extends {
        id: number
        published?: boolean
    },
>(payload: OpportunityShape, ctx?: NextPageContext) {
    return api(
        `/api/admin/opportunities/${payload.id}/edit`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * POST edit an investment opportunity
 */
export function fetchMessages(accountId: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/accounts/${accountId}/messages`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * POST edit corporate person
 */
export function pairTransaction({ transactionId, accountId }: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/transactions/${transactionId}/pair`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify({ accountId }),
        },
        ctx,
    )
}

export function updateTransaction(
    id: number,
    payload: { note?: string; type?: string },
    ctx?: NextPageContext,
) {
    return api(
        `/api/admin/transactions/${id}`,
        {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function fetchOpportunityInvestments(opportunityId: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/opportunities/${opportunityId}/investments`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

export function updateReferrals(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/referrals`,
        {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function fetchAccountsByFulltextWithWallet(searchTerm: string, ctx?: NextPageContext) {
    return api(
        `/api/admin/accounts/fulltext/${encodeURIComponent(searchTerm)}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 *  POST download account's transaction history pdf
 */
export function exportAccountTransactions(
    payload: {
        // amount is formated string here - most likely formatCurrency was used on it
        items: { amount: string; createdAt: string; type: string; note: string }[]
        account_id: number
    },
    ctx?: NextPageContext,
) {
    return api(
        `/api/admin/accounts/transactions/export`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/pdf',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * POST generate tax report for given account
 */
export function generateTaxReport(year: number, account_id: number) {
    return api(`/api/admin/accounts/generate-account-tax-report`, {
        method: 'POST',
        headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
        },
        body: stringify({ year, account_id }),
    })
}

export function sendAdminEmail(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/admin/accounts/send-emails`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function sendAdminOpportunityEmail(
    payload: {
        subject: string
        body: string
        accounts: number[]
        opportunityId: number
    },
    ctx?: NextPageContext,
) {
    return api(
        `/api/admin/opportunities/send-emails`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function transactionAMLCheck(transactionId: TODO, payload: TODO) {
    return api(`/api/admin/transactions/${transactionId}/aml-check`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

export function createReferral(payload: TODO) {
    return api('/api/admin/referrals', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

export function fetchAccountScans(accountId: TODO) {
    return api(`/api/admin/accounts/${accountId}/scans`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}

export function performScan(accountId: TODO) {
    return api(`/api/admin/accounts/${accountId}/perform-scan`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
}
