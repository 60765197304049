import React, { createContext, useState, useContext, useRef, useReducer, ReactNode } from 'react'
import { useTransition, animated } from 'react-spring'
import cx from 'classnames'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { NextPage, NextPageContext } from 'next'
import Toast from '../components/Toast'
import css from '../styles/Toast.module.sass'

type ToastContext = (options: ToastOptions) => void

export const ToastContext = createContext<ToastContext>({} as ToastContext)

export const useToast = () => {
    return useContext(ToastContext)
}

export const withToast = (Component: TODO) => {
    const Toast: NextPage = props => {
        const addToast = useToast()

        return <Component {...props} addToast={addToast} />
    }

    // We want to hoist statics like `getLayout`
    hoistNonReactStatics(Toast, Component)

    Toast.getInitialProps = (ctx: NextPageContext) => {
        if (typeof Component.getInitialProps === 'function') {
            const pageProps = Component.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return Toast
}

function dragReducer(state: TODO, action: TODO) {
    switch (action.type) {
        case 'start':
            return {
                draggingItemKey: action.payload.key,
                referenceX: action.payload.x,
                currentX: action.payload.x,
            }
        case 'end':
            return {
                isDragging: false,
                referenceX: null,
                draggingItemKey: null,
            }
        case 'move':
            return { ...state, currentX: action.payload }
        default:
            throw new Error()
    }
}

const DEFAULT_TIMEOUT = 5000

interface ToastOptions {
    timeout?: number
    message: string
    type: 'success' | 'error' | 'info'
}

type Toast = ToastOptions & {
    key: number
}

export function ToastProvider({ children }: { children: ReactNode }) {
    const refMap = useRef<Record<number, HTMLElement>>({})
    const timers = useRef<number[]>([])
    const [toasts, setToasts] = useState<Toast[]>([])
    const [currentToastIndex, setCurrentToastIndex] = useState(0)
    const [dragState, dragDispatch] = useReducer(dragReducer, {})

    const transition = useTransition(toasts, {
        keys: (item: Toast) => item.key,
        from: { opacity: 0, transform: 'translate3d(0,-16px,0)' },
        enter: item => next => {
            return next({
                opacity: 1,
                transform: 'translate3d(0,0,0)',
                height: refMap.current[item.key].offsetHeight,
            })
        },
        leave: () => async next => {
            await next({ opacity: 0 })
            await next({ height: 0 })
        },
    })

    const removeToast = (key: number) => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.key !== key))
    }

    const clearTimers = () => {
        timers.current.forEach(clearTimeout)
    }

    const restartTimers = () => {
        timers.current = []

        toasts.forEach(toast => {
            const timer = setTimeout(() => {
                removeToast(toast.key)
            }, toast.timeout) as unknown as number

            timers.current.push(timer)
        })
    }

    return (
        <ToastContext.Provider
            value={({ message, type, timeout }: ToastOptions) => {
                setToasts(prevToasts => [
                    ...prevToasts,
                    {
                        message,
                        key: currentToastIndex,
                        type,
                        timeout: timeout || DEFAULT_TIMEOUT,
                    },
                ])
                const timer = setTimeout(() => {
                    removeToast(currentToastIndex)
                }, timeout || DEFAULT_TIMEOUT) as unknown as number
                timers.current.push(timer)
                setCurrentToastIndex(prevIndex => prevIndex + 1)
            }}
        >
            <>
                <div className={cx(css.container, css.legacyFont)}>
                    {transition((style, item) => {
                        return (
                            <animated.div
                                // @ts-ignore
                                style={style}
                                ref={ref => {
                                    if (ref) {
                                        refMap.current[item.key] = ref
                                    }
                                }}
                                onMouseOver={clearTimers}
                                onTouchStart={e => {
                                    dragDispatch({
                                        type: 'start',
                                        payload: {
                                            x: e.targetTouches[0].clientX,
                                            key: item.key,
                                        },
                                    })
                                    clearTimers()
                                }}
                                onTouchMove={e => {
                                    const currentX = e.touches[0].clientX

                                    dragDispatch({
                                        type: 'move',
                                        payload: currentX,
                                    })
                                }}
                                onFocus={clearTimers}
                                onTouchEnd={() => {
                                    const { referenceX, currentX } = dragState

                                    if (Math.abs(referenceX - currentX) > 100) {
                                        removeToast(item.key)
                                    } else {
                                        dragDispatch({ type: 'end' })
                                    }

                                    restartTimers()
                                }}
                                onMouseLeave={restartTimers}
                                onBlur={restartTimers}
                            >
                                <div
                                    style={
                                        dragState.draggingItemKey === item.key
                                            ? {
                                                  transform: `translateX(${
                                                      dragState.currentX - dragState.referenceX
                                                  }px)`,
                                                  opacity:
                                                      1 -
                                                      (Math.abs(
                                                          dragState.currentX - dragState.referenceX,
                                                      ) *
                                                          0.8) /
                                                          100,
                                              }
                                            : { transform: 'none', opacity: 1 }
                                    }
                                >
                                    <Toast
                                        onRemoveClick={() => {
                                            removeToast(item.key)
                                        }}
                                        type={item.type}
                                    >
                                        {item.message}
                                    </Toast>
                                </div>
                            </animated.div>
                        )
                    })}
                </div>

                {children}
            </>
        </ToastContext.Provider>
    )
}
