import { theme } from '@upvestcz/shared-components'
import { extendTheme, ThemeOverride } from '@chakra-ui/react'

const themeOverride: ThemeOverride = {
    textStyles: {
        heading1: {
            fontFamily: 'body',
            fontWeight: 700,
        },
        heading2: {
            fontFamily: 'body',
            fontWeight: 700,
        },
        heading3: {
            fontFamily: 'body',
            fontWeight: 700,
        },
    },
}

export const adminTheme = extendTheme(themeOverride, theme)
