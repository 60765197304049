import { useEffect, useRef } from 'react'

// https://usehooks.com/usePrevious/
export function usePrevious<T>(value: T): T | undefined {
    // Source: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current
}
