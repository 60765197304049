import { forwardRef } from 'react'
import css from '../styles/Button.module.sass'

const Button = forwardRef<HTMLButtonElement, TODO>(function Button(
    {
        type,
        className,
        onClick,
        level,
        disabled,
        size,
        isLoading,
        'data-test-id': dataTestId,
        children,
        ...props
    },
    ref,
) {
    const actualClassName = [css.button]

    actualClassName.push(css[level])

    if (size) {
        actualClassName.push(css[size])
    }

    if (isLoading) {
        actualClassName.push(css.isLoading)
    }

    actualClassName.push(className)

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            type={type}
            className={actualClassName.join(' ')}
            onClick={onClick}
            disabled={disabled}
            data-test-id={dataTestId}
            ref={ref}
            {...props}
        >
            <span>{children}</span>
        </button>
    )
})

Button.defaultProps = {
    type: 'button',
    level: 'primary',
    className: '',
    onClick: undefined,
    disabled: false,
    size: undefined,
    isLoading: false,
    'data-test-id': undefined,
}

export default Button
