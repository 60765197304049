import React from 'react'
import { ChakraProvider, ScrollInfoProvider, Box } from '@upvestcz/shared-components'
import { ApolloProvider } from '@apollo/client'
import { PageProps, useApolloClient } from '@upvestcz/common/graphql/client'
import { UniqueIdProvider } from '@upvestcz/common/hooks'
import { ToastProvider } from '../lib/toast'
import css from '../styles/MyApp.module.sass'
import CSSReset from './_chakraReset' // custom reset in a css layer
import { adminTheme } from '../theme'

const LegacyPageContentProvider = ({
    children,
    pageProps,
}: {
    children: React.ReactNode
    pageProps: PageProps
}) => {
    const client = useApolloClient(pageProps)

    return (
        <div className={css.container}>
            <ChakraProvider theme={adminTheme} resetCSS={false}>
                <CSSReset />
                <ScrollInfoProvider>
                    <UniqueIdProvider>
                        <ApolloProvider client={client}>
                            <ToastProvider>
                                <Box minWidth="100%" minHeight="100vh">
                                    {children}
                                </Box>
                            </ToastProvider>
                        </ApolloProvider>
                    </UniqueIdProvider>
                </ScrollInfoProvider>
            </ChakraProvider>
        </div>
    )
}

export default LegacyPageContentProvider
